@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300&family=Nunito+Sans:wght@200&family=Teko:wght@500&display=swap");
$pri-color: #868686;
html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}
::-webkit-scrollbar {
  background-color: white;
}
.App {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  overflow: hidden;
}

.container {
  width: 100%;
  height: 100vh;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
  overflow-y: scroll;

  .section {
    height: 100vh;

    scroll-snap-align: start;
    width: 60%;
    left: 25%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  .sections {
    height: 100vh;

    scroll-snap-align: start;
    width: 60%;
    left: 25%;
    position: relative;
  }
}
.About {
  position: fixed;
  left: 5%;

  height: 100vh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    color: #282c34;
    font-family: "Montserrat", sans-serif;
    font-size: 18px;

    text-align: center;
  }
  img {
    width: 200px;
    border-radius: 50%;
    height: 200px;
    animation: transitionImg 1s;
  }
  ul {
    display: flex;
    justify-content: center;
    list-style: none;

    padding: 0px !important;
  }
  li {
    margin: 0px 10px 0px 10px;
  }
  ul li a {
    color: #282c34;
  }
}
#alert {
  background-color: #2bca1f;
  width: fit-content;
  padding: 5px 8px 5px 8px;
  color: white;
  border-radius: 5px;
  display: none;
}
.Nav {
  height: 100vh;
  width: auto;
  position: fixed;
  right: 5%;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  ul {
    list-style: none;
    padding: 10px;

    li {
      font-size: 20px;
      text-align: end;
      margin-top: 50px;
      color: #868686;
      font-weight: bold;
      font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    }
    a {
      text-decoration: none;
      color: #868686;
    }
  }
}

.Me {
  position: relative;

  width: 100%;
  left: 10%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading-p {
  font-family: "Nunito", sans-serif;
  font-size: 38px;
  color: #868686;
  animation: transitionInFirst 1s;
}
.Info {
  animation: transitionIn 1.8s;

  width: 100%;
  padding: 5px;
  span {
    color: $pri-color;
    font-size: 18px;
  }
}

.Card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  scroll-snap-align: start;
  border-radius: 8px;
  width: 400px;
  margin-left: 15px;
  padding: 0px;
  height: 40vh;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background-color: #eeeeee;
  transition: 0.5s;
  a {
    text-decoration: none;
    color: #212121;
  }
  img {
    width: 100%;
    height: 200px;
  }
  &:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
}
.CardFooter {
  padding: 0px 10px 0px 10px;
  p {
    font-weight: bold;
    font-family: "Nunito Sans", sans-serif;
  }
}
.Projects {
  position: relative;

  height: 100vh;
  display: flex;

  align-items: center;
  justify-content: center;

  .project-scroll {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    overflow-x: hidden;

    // scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    width: 100%;
    height: 50vh;
  }

  img {
    width: 370px;
    transition: 0.5s;
  }
}
.typing {
  font-size: 25px;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.Learnings {
  position: relative;

  width: 100%;

  height: 100vh;
  display: flex;
  gap: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .learnings-type {
    width: 100%;
  }
  .learnings-text {
    height: 100%;
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  span {
    color: $pri-color;
    font-size: 18px;
  }
}
.Contact {
  position: relative;
  width: 100%;

  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  input {
    padding: 10px;
    border: none;
    border-bottom: 1.5px solid #eaeaea;
  }
  input:focus {
    outline: none;
    border-bottom: 1.5px solid #212121;
  }
  #email {
    text-decoration: underline;
    color: $pri-color;
    font-size: 15px;
  }
}
.pad-10 {
  padding: 10px;
}
.grid-2-2 {
  display: grid;
  grid-template-columns: 5fr 1fr;
  gap: 50px;
}

.submit-btn {
  display: flex;
  justify-content: center;
  align-content: flex-end;

  button {
    background-color: white;
    border: none;
    outline: none;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: flex-end;
    border: 1px solid black;
    text-transform: uppercase;
    font-weight: bold;
    transition: 0.5s;

    &:hover {
      background-color: #212121 !important;
      color: white;
      cursor: pointer;
    }
  }
}
.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}

.Input {
  display: flex;
  flex-direction: column;
  label {
    font-family: "Teko", sans-serif;
  }
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes transitionIn {
  from {
    opacity: 0;
    transform: translateY(800px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes transitionImg {
  from {
    opacity: 0;
    transform: translateX(-500px);
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes transitionInFirst {
  from {
    opacity: 0;
    transform: translateX(-200px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
